import { graphql } from "gatsby"
import React from "react"
import { TTheme } from "../../types"
import { BlockContent } from "../components/block-content"
import { Container } from "../components/container"
import { Grid } from "../components/grid"
import { H2 } from "../components/headings"
import { HeroImage } from "../components/hero-image"
import SEO from "../components/seo"
import { Typography } from "../components/typography"
import { generateSrcSet, getAspectRatio, imageUrlFor } from "../lib/image"
import mq from "../theme/mq"
import styled from "../theme/styled"

interface PageContext {
	title: string
	description: string
	image: {
		asset: {
			_id: string
			fluid: {
				aspectRatio: number
				base64: string
				src: string
				srcSet: string
				sizes: string
			}
			metadata: {
				dimensions: {
					width: number
					height: number
				}
				palette: {
					dominant: {
						background: string
					}
				}
			}
		}
		crop: {
			top: number
			left: number
			bottom: number
			right: number
		}
	}
	tagLine: string
}

export const query = graphql`
	query ArtistTemplate($artistId: String!) {
		artist: sanityArtist(id: { eq: $artistId }) {
			name
			description: _rawDescription
			image {
				asset {
					fixed(width: 800) {
						src
						srcSet
						aspectRatio
					}
				}
			}
		}
		artwork: allSanityArt(filter: { artist: { id: { eq: $artistId } } }) {
			nodes {
				createdAt: _createdAt(difference: "seconds")
				id
				title
				material {
					name
				}
				price
				sold
				artist {
					id
					name
					description: _rawDescription
				}
				image {
					asset {
						small: fixed(width: 400) {
							src
							aspectRatio
						}
						large: fixed(width: 800) {
							src
						}
					}
				}
				slug {
					current
				}
			}
		}
	}
`

export default function ArtistTemplate({
	data,
	pageContext,
}: {
	data: any
	pageContext: PageContext
}) {
	const { image, tagLine, title, description } = pageContext
	const imageBuilder = imageUrlFor(image).fit("crop")
	const imageUrl = imageBuilder.url()
	const imageSrcSet = generateSrcSet(
		imageBuilder,
		image.asset.fluid.sizes.split(" ")
	)

	return (
		<>
			<SEO
				title={`${title} | ${data.artist.name}`}
				description={description}
				image={image.asset.fluid.src}
			/>
			<HeroImage
				imageAspectRatio={
					getAspectRatio(
						image.crop,
						image.asset.metadata.dimensions.width,
						image.asset.metadata.dimensions.height
					) || image.asset.fluid.aspectRatio
				}
				imageSrc={imageUrl || image.asset.fluid.src}
				imageSrcSet={imageSrcSet || image.asset.fluid.srcSet}
				imageBackgroundColor={
					image.asset.metadata?.palette?.dominant?.background
				}
				title={title}
				description={tagLine}
			/>
			<Container
				css={(theme: TTheme) => ({
					marginBottom: theme.spacing.xxl,
					[mq.large]: {
						padding: `0 12.5%`,
					},
					[mq.container]: {
						padding: `0 12.5%`,
					},
				})}
			>
				<div
					css={(theme: TTheme) => ({
						margin: `${theme.spacing.xxl} 0`,
						[mq.medium]: {
							display: `grid`,
							gridTemplateColumns: `40% 1fr`,
							gridColumnGap: theme.spacing.xl,
							margin: `${theme.spacing.xxxl} 0`,
						},
						[mq.large]: {
							gridColumnGap: theme.spacing.xxl,
							margin: `${theme.spacing.xxxl} 0`,
						},
						[mq.xlarge]: {
							gridColumnGap: theme.spacing.xxxl,
						},
						[mq.xxlarge]: {
							gridColumnGap: theme.spacing.xxxxl,
							margin: `${theme.spacing.xxxxl} 0`,
						},
					})}
				>
					<div>
						<div
							css={(theme: TTheme) => ({
								position: `relative`,
								width: `100%`,
								height: 0,
								overflow: `hidden`,
								paddingBottom: `${100 /
									data.artist.image.asset.fixed
										.aspectRatio}%`,
								marginBottom: theme.spacing.l,
								[mq.large]: {
									marginBottom: 0,
								},
							})}
						>
							<img
								src={data.artist.image.asset.fixed.src}
								srcSet={data.artist.image.asset.fixed.srcSet}
								css={{
									position: `absolute`,
									top: 0,
									left: 0,
									width: `100%`,
									height: `100%`,
									zIndex: 1,
								}}
								width={700}
								height={Math.round(
									700 *
										(1 /
											data.artist.image.asset.fixed
												.aspectRatio)
								)}
							/>
						</div>
					</div>
					<div>
						<H2
							css={(theme: TTheme) => ({
								marginBottom: theme.spacing.m,
								[mq.large]: {
									marginBottom: theme.spacing.xl,
								},
							})}
						>
							{data.artist.name}
						</H2>
						<BlockContent body={data.artist.description} />
					</div>
				</div>
				<div
					css={(theme: TTheme) => ({
						display: `flex`,
						borderBottom: `1px solid ${theme.colours.tones.neutral}`,
						marginBottom: theme.spacing.xxl,
					})}
				>
					<Tab>Work by the artist</Tab>
				</div>
				<Grid items={data.artwork.nodes} />
			</Container>
		</>
	)
}

const Tab = styled(Typography)(props => ({
	marginBottom: `-1px`,
	color: props.theme.colours.primary,
	borderBottom: `1px solid ${props.theme.colours.primary}`,
	padding: `${props.theme.spacing.xs} 0`,
	[mq.medium]: {
		padding: 0,
		fontSize: `1.6rem`,
	},
	[mq.large]: {
		fontSize: `1.8rem`,
	},
}))
