import sanityConfig from "../../../studio/sanity.json"
import imageUrlBuilder from "@sanity/image-url"
import { SanityImageSource } from "@sanity/image-url/lib/types/types"

const builder = imageUrlBuilder({
	projectId: sanityConfig.api.projectId,
	dataset:
		process.env.NODE_ENV === "development"
			? sanityConfig.env.development.api.dataset
			: sanityConfig.api.dataset,
})

export function imageUrlFor(source: SanityImageSource) {
	return builder.image(source)
}

export function getAspectRatio(
	crop: {
		top: number
		left: number
		bottom: number
		right: number
	} | null,
	width: number,
	height: number
) {
	if (!crop) {
		return null
	}
	return (
		((1 - crop.right - crop.left) * width) /
		((1 - crop.top - crop.bottom) * height)
	)
}

export function generateSrcSet(builder: any, sizes: string[]) {
	return sizes.map(size => `${builder.size(size).url()} ${size}w`).join(", ")
}
