import BlockContentToReact from "@sanity/block-content-to-react"
import { Typography } from "./typography"
import { H1, H2, H3, H4, H5 } from "./headings"

type Type = { _type: string }

const BlockQuote = Typography.withComponent("blockquote")

const serializers = {
	types: {
		block(props: any) {
			switch (props.node.style) {
				case "h1":
					return <H1>{props.children}</H1>

				case "h2":
					return <H2>{props.children}</H2>

				case "h3":
					return <H3>{props.children}</H3>

				case "h4":
					return <H4>{props.children}</H4>

				case "h5":
					return <H5>{props.children}</H5>

				case "h6":
					return <H5>{props.children}</H5>

				case "blockquote":
					return <BlockQuote>{props.children}</BlockQuote>

				default:
					return (
						<Typography
							css={{
								":empty": {
									height: `1.5em`
								},
							}}
						>
							{props.children}
						</Typography>
					)
			}
		},
	},
}

export const BlockContent = ({ body }: { body: Type | Type[] }) => (
	<BlockContentToReact blocks={body} serializers={serializers} />
)
