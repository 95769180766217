import { Link } from "gatsby"
import { TTheme } from "../../types"
import { InvertedButton } from "./button"
import { H1 } from "./headings"
import { Typography } from "./typography"

interface HeroImageProps {
	imageSrc: string
	imageSrcSet?: string
	imageBackgroundBase64?: string
	imageBackgroundColor?: string | undefined
	imageAspectRatio: number
	title: string
	description: string
	linkTo?: string
	linkTitle?: string
}

const InvertedButtonLink = InvertedButton.withComponent(Link)

export const HeroImage = ({
	imageSrc,
	imageSrcSet,
	imageBackgroundColor = "#fff",
	imageAspectRatio,
	imageBackgroundBase64,
	title,
	description,
	linkTo,
	linkTitle,
}: HeroImageProps) => (
	<div
		css={{
			position: `relative`,
			height: 0,
			minHeight: `200px`,
			maxHeight: `90vh`,
			paddingBottom: `${100 / imageAspectRatio}%`,
			backgroundColor: imageBackgroundColor,
			backgroundImage: imageBackgroundBase64
				? `url(${imageBackgroundBase64})`
				: `none`,
		}}
	>
		<img
			src={imageSrc}
			srcSet={imageSrcSet}
			css={{
				position: `absolute`,
				top: 0,
				left: 0,
				width: `100%`,
				height: `100%`,
				zIndex: 1,
				objectFit: `cover`,
			}}
		/>
		<div
			css={{
				position: `absolute`,
				top: 0,
				left: 0,
				width: `100%`,
				height: `100%`,
				backgroundImage: `radial-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0) 80%)`,
				zIndex: 2,
			}}
		/>
		<div
			css={(theme: TTheme) => ({
				position: `absolute`,
				top: 0,
				left: 0,
				width: `100%`,
				height: `100%`,
				zIndex: 3,
				display: `flex`,
				alignItems: `center`,
				justifyContent: `center`,
				flexDirection: `column`,
				paddingBottom: theme.spacing.m,
			})}
		>
			<H1
				css={(theme: TTheme) => ({
					color: theme.colours.inverted,
					marginBottom: theme.spacing.m,
				})}
			>
				{title}
			</H1>
			<Typography
				size="xxs"
				css={(theme: TTheme) => ({
					color: theme.colours.inverted,
				})}
			>
				{description}
			</Typography>
			{linkTo && linkTitle && (
				<InvertedButtonLink
					to={linkTo}
					css={(theme: TTheme) => ({
						marginTop: theme.spacing.xl,
					})}
				>
					{linkTitle}
				</InvertedButtonLink>
			)}
		</div>
	</div>
)
